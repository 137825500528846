(function($) {

  var canvas, stage, exportRoot, anim_container, dom_overlay_container, fnStartAnimation;



  var handleComplete = function(evt,comp) {
    //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
    var lib=comp.getLibrary();
    var ss=comp.getSpriteSheet();
    exportRoot = new lib.canopyLogoAnimationHTML5Canvas();
    stage = new lib.Stage(canvas);

    if(exportRoot){
      $("#logo-fallback").hide();
    }

    //Registers the "tick" event listener.
    fnStartAnimation = function() {
      stage.addChild(exportRoot);
      createjs.Ticker.setFPS(lib.properties.fps);
      createjs.Ticker.addEventListener("tick", stage);
    };

    //Code to support hidpi screens and responsive scaling.
    function makeResponsive(isResp, respDim, isScale, scaleType, target) {
      var lastW, lastH, lastS=1;

      function resizeCanvas() {
        var w = lib.properties.width;
        var h = lib.properties.height;

        var iw = target.width();
        var ih = target.height();

        var pRatio = window.devicePixelRatio || 1, xRatio=iw/w, yRatio=ih/h, sRatio=1;

        if(isResp) {
          if((respDim==='width'&&lastW===iw) || (respDim==='height'&&lastH===ih)) {
            sRatio = lastS;
          }
          else if(!isScale) {
            if(iw<w || ih<h){
              sRatio = Math.min(xRatio, yRatio);
            }
          }
          else if(scaleType===1) {
            sRatio = Math.min(xRatio, yRatio);
          }
          else if(scaleType===2) {
            sRatio = Math.max(xRatio, yRatio);
          }
        }

        canvas.width = w*pRatio*sRatio;
        canvas.height = h*pRatio*sRatio;
        canvas.style.width = dom_overlay_container.style.width = anim_container.style.width =  w*sRatio+'px';
        canvas.style.height = anim_container.style.height = dom_overlay_container.style.height = h*sRatio+'px';
        stage.scaleX = pRatio*sRatio;
        stage.scaleY = pRatio*sRatio;
        lastW = iw; lastH = ih; lastS = sRatio;
        stage.tickOnUpdate = false;
        stage.update();
        stage.tickOnUpdate = true;
      }

      window.addEventListener('resize', resizeCanvas);
      window.addEventListener('scroll', resizeCanvas);
      resizeCanvas();
    }
    makeResponsive(true, 'width', true, 1, $("#cp-header-logo"));

    AdobeAn.compositionLoaded(lib.properties.id);
    fnStartAnimation();
  };

  function init_logo() {
    console.log('Revving up logo canvas animation');
    canvas = document.getElementById("cp-logo-canvas");
    anim_container = document.getElementById("cp_logo_animation_container");
    dom_overlay_container = document.getElementById("cp_logo_animation_dom_overlay_container");
    var comp=AdobeAn.getComposition("0AD8ACEEA4184FC6B6BFA09D21897239");
    var lib=comp.getLibrary();
    handleComplete({},comp);
  }

  // LOGO ANIM CANVAS INIT
  $(document).ready(function() {
    init_logo();
  });
})(jQuery); // Fully reference jQuery after this point.
